<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Email confirmation expired</div>
        <p class="text-center opasity--text mt-3">
          Sorry, but this email confirmation link expired. Please ask system administrator to send a new link
        </p>
        <router-link class="d-block opasity--text mt-5" to="/login"><v-icon left>mdi-arrow-left</v-icon> Back to Sign In</router-link>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
